import { DEVICE } from '../types/reducer.types';
import fetchStates from '../types/fetchState.types';
import InterfaceReducerDevice from '../intefaces/InterfaceReducerDevice';


const DEFAULT_DEVICE_STATE: InterfaceReducerDevice = { 
  status: '',
  fields: [],
  msg: [],
  devices_wallbox_count: 0,
  devices_wallbox: [],
  device_wallbox: [],
  device_wallbox_charging_events_count: 0,
  device_wallbox_charging_events: []
}


const device = (state = DEFAULT_DEVICE_STATE, action: any) => {
  if(action.authorizationHeader) {
    localStorage.setItem('accessToken', action.authorizationHeader);
  }
  switch(action.type) {
    case DEVICE.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: [],
        fields: [],
      };
    case DEVICE.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error,
        msg: action.errors,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case DEVICE.FETCH_DEVICE_WALLBOX_COUNT_SUCCESS:
      return {
        ...state,
        devices_wallbox_count: action.devices_wallbox_count,
        status: fetchStates.success,
      };
    case DEVICE.FETCH_DEVICE_WALLBOX_SUCCESS:
      return {
        ...state,
        devices_wallbox: action.devices_wallbox,
        devices_wallbox_count: action.devices_wallbox_count,
        status: fetchStates.success,
      };
    case DEVICE.FETCH_DEVICE_WALLBOX_BY_ID_SUCCESS:
      return {
        ...state,
        device_wallbox: action.device_wallbox,
        status: fetchStates.success,
      };
    case DEVICE.FETCH_UPDATE_DEVICE_WALLBOX_BY_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case DEVICE.FETCH_DELETE_DEVICE_WALLBOX_BY_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case DEVICE.FETCH_INSERT_DEVICE_WALLBOX_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case DEVICE.FETCH_DEVICE_WALLBOX_CHARGING_EVENTS_SUCCESS:
      return {
        ...state,
        device_wallbox_charging_events_count: action.device_wallbox_charging_events_count,
        device_wallbox_charging_events: action.device_wallbox_charging_events,
        status: fetchStates.success,
      };
    default:
      return state;
  }
};


export default device;