import { AUTH } from '../types/reducer.types';
import fetchStates from '../types/fetchState.types';
import InterfaceReducerAuth from '../intefaces/InterfaceReducerAuth';


const DEFAULT_AUTH_STATE: InterfaceReducerAuth = { 
  status: '',
  fields: [],
  msg: [],
  authenticated: (String(localStorage.getItem('authenticated')).toLowerCase() === 'true') || false,
  user_authorized: {},
  user_energy_cost: '',
  login_logs: [],
  login_logs_count: 0
}


const insertAuthIntoLocalStorage = (action: any) => {
  localStorage.setItem('authenticated', 'true');
  localStorage.setItem('user_authorized', JSON.stringify(action.user_authorized));
  localStorage.setItem('user_energy_cost', JSON.stringify(action.user_energy_cost));
  localStorage.setItem('user_currency_format', JSON.stringify('PLN'));
}


const auth = (state = DEFAULT_AUTH_STATE, action: any) => {
  if(action.authorizationHeader) {
    localStorage.setItem('accessToken', action.authorizationHeader);
  }
  if(action.logout) { 
    localStorage.clear();
    return {
      authenticated: false
    }
  }
  switch(action.type) {
    case AUTH.FETCH:
      return { 
        ...state,
        status: fetchStates.fetching,
        msg: [],
        fields: [],
      };
    case AUTH.FETCH_ERROR:
      return { 
        ...state,
        status: fetchStates.error,
        msg: action.errors,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case AUTH.FETCH_REGISTER_PIN_SUCCESS:
      return {
        ...state,
        status: fetchStates.success
      };
    case AUTH.FETCH_REGISTER_PIN_RESEND_SUCCESS:
      return {
        ...state,
        status: fetchStates.success
      }
    case AUTH.FETCH_REGISTER_SUCCESS:
      return {
        ...state,
        status: fetchStates.success
      };
    case AUTH.FETCH_LOGIN_SUCCESS:
      insertAuthIntoLocalStorage(action);
      return {
        ...state,
        user_authorized: action.user_authorized,
        user_energy_cost: action.user_anergy_cost,
        user_auth_groups: action.user_auth_groups,
        status: fetchStates.success,
        authenticated: true,
      };
    case AUTH.FETCH_LOGOUT_SUCCESS:
      localStorage.clear();
      return {
        status: fetchStates.success,
        msg: action.success,
        authenticated: false,
      };
    case AUTH.FETCH_LOGOUT_EVERYWHERE_SUCCESS:
      localStorage.clear();
      return {
        status: fetchStates.success,
        msg: action.success,
        authenticated: false,
      };
    case AUTH.FETCH_AUTHENTICATED_SUCCESS:
      insertAuthIntoLocalStorage(action);
      return {
        ...state,
        user_authorized: action.user_authorized,
        user_energy_cost: action.user_energy_cost,
        user_auth_groups: action.user_auth_groups,
        status: fetchStates.success,
        msg: action.success,
        authenticated: true,
      };
    case AUTH.FETCH_PASSWORD_FORGOT_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        reset_token_timestamp: action.reset_token_timestamp,
      };
    case AUTH.FETCH_PASSWORD_CHECK_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        reset_token: action.reset_token,
      };
    case AUTH.FETCH_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case AUTH.FETCH_PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case AUTH.FETCH_ENERGY_COST_UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      }
    case AUTH.FETCH_LOGIN_LOGS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        login_logs: action.login_logs,
        login_logs_count: action.login_logs_count,
      }
    case AUTH.FETCH_ENERGY_COST_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        user_energy_cost: action.user_energy_cost,
      }
    default:
      return state;
  }
};


export default auth;