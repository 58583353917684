import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { RootState } from '../../../reducers';
import { connect } from 'react-redux';
import { registerPinResend } from '../../../actions/auth.action';
import { IconWarning } from '../../shared/icons/IconsForm';
import TemplatePageFullwidth from '../../shared/template-page-fullwidth/TemplatePageFullwidth';
import { IconLogoDarkDesktop } from '../../shared/icons/IconsLogo';
import classnames from 'classnames';
import Language from '../../shared/language/Language';
import { tr, trans } from '../../../translation/translations';
import fetchStates from '../../../types/fetchState.types';
import InterfaceReducerAuth from '../../../intefaces/InterfaceReducerAuth';
import { IconBack } from '../../shared/icons/IconsNavigation';
import withRouter from '../../hoc/router/WithRouter';


interface AuthRegisterPinResendProps {
  router: { location: string, navigate: (to: any) => any },
  auth: InterfaceReducerAuth,
  registerPinResend: (options: { register_email: string }) => Promise<any>,
};


interface AuthRegisterPinResendState {
  formSubmitted: boolean,
  register_email: string,
  passwordShow: boolean,
  fields: any,
  formError: boolean
};


export class AuthRegisterPinResend extends React.Component<AuthRegisterPinResendProps, AuthRegisterPinResendState> {


  state = {
    formSubmitted: false,
    register_email: '',
    passwordShow: false,
    fields: {},
    formError: false
  };


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { register_email } = this.state;
    await this.props.registerPinResend({ register_email });
    this.setState({ formSubmitted: false });
    if(this.props.auth.status === fetchStates.error) {
      this.setState({ formError: true });
      const self = this;
      setTimeout(function() { 
        if(self.state.formError === true) {
          self.setState({ formError: false });
        }
      }, 250);
    }
    if(this.props.auth.status === fetchStates.success) {
      this.props.router.navigate('/register-pin');
    }
    this.setState({ formSubmitted: false });
  };


  render() {

    const { authenticated, fields, status } = this.props.auth;
    const { formSubmitted, register_email, formError } = this.state;

    return authenticated ? <Navigate to="/" /> : (
      <TemplatePageFullwidth>
        <Language />
        <div className="page page--auth page__login">
          <div className="auth-back-button">
            <Link to="/">
              <IconBack color="#000" size={40} />
            </Link>
          </div>


          <div className={classnames('form-wrapper form-wrapper--auth', { 'form-error': formError })}>
            <div className="logo logo--auth">
              <IconLogoDarkDesktop width={240} height={80} />
            </div>
            <h1 className="title title--main title--auth">{ tr(trans.viewRegisterPinResend.pageTitle) }</h1>
            <p className="paragraph paragraph--auth">{ tr(trans.viewRegisterPinResend.paragraphInstructions) }</p>
            <form className="form form--auth" onSubmit={this.handleOnSubmit}>
              <div className="form-group form-group--auth">
                <label 
                  htmlFor="register_email"
                  className={classnames('label label--auth', { 'label--error': fields && fields.includes('register_email') })}
                >
                  { fields && fields.includes('register_email') && <IconWarning size={16} color="#fff" /> }{ tr(trans.viewRegisterPinResend.form.labelEmail) }
                </label>
                <div className="input-group input-group--auth">
                  <input
                    id="register_email"
                    type="email"
                    autoComplete="register_email"
                    value={register_email}
                    onChange={e => this.setState({ register_email: e.target.value })}
                    className={classnames('input input--auth', { 'input--error': fields && fields.includes('register_email') })}
                  />
                </div>
              </div>

              <div className="form-group form-group--auth">
                <input 
                  type="submit" 
                  className="button button--auth button--large button-margin--bottom" 
                  value={ tr(trans.viewRegisterPinResend.form.buttonResendRegistrationPin) } 
                  disabled={formSubmitted && status === 'fetching' ? true : false}
                />
              </div>


            </form>
          </div>
        </div>
      </TemplatePageFullwidth>
    );
  }
};


export default withRouter(connect(
  ({ auth }: RootState) => ({ auth }),
  { registerPinResend }
)(AuthRegisterPinResend));