import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchUsers } from '../../../actions/person.action';

import WithAuthentication from '../../hoc/auth/WithAuthentication';
import withRouter from '../../hoc/router/WithRouter';
import withWs from '../../hoc/ws/WithWs'

import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import InterfaceReducerPerson from '../../../intefaces/InterfaceReducerPerson';
import { Link } from 'react-router-dom';
import { tr, trans } from '../../../translation/translations';
import Box from '../../shared/box/Box';
import { IconBoxDelete, IconBoxEdit } from '../../shared/icons/IconsTables';
import BoxTitlebar from '../../shared/box-titlebar/BoxTitlebar';
import ButtonTitlebar from '../../shared/button-titlebar/ButtonTitlebar';
import moment from 'moment';
import WithPreloader from '../../hoc/preloader/WithPreloader';
import TableFooter from '../../shared/table-footer/TableFooter';


interface UsersProps {
  router: { location: string, navigate: (to: any) => any },
  person: InterfaceReducerPerson,
  fetchUsers: (options: { limit: number, skip: number }) => Promise<void>
}


interface UsersState {
  users: Array<any>,
  users_count: number,
  currentPage: number,
  perPage: number,
}


export class Users extends React.Component<UsersProps, UsersState> {


  state = {
    users: [],
    users_count: 0,
    perPage: 10,
    currentPage: 0,
  }


  componentDidMount(): void {
    this.getAllUsers();
  }


  getAllUsers = async () => {
    const { perPage, currentPage } = this.state;
    this.reloadPage({ perPage, currentPage });
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  });
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  });
    this.props.router.navigate(`/users?page=0&perPage=${e.target.value}`);
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage });
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage });
    this.props.router.navigate(`/users?page=${prevPage}&perPage=${this.state.perPage}`);
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage });
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage });
    this.props.router.navigate(`/users?page=${nextPage}&perPage=${this.state.perPage}`);
  }


  reloadPage = async (options: { perPage: number, currentPage: number }) => {
    await this.props.fetchUsers({ limit: options.perPage, skip: options.perPage * options.currentPage });
    if(this.props.person.status === fetchStates.success) {
      const { users, users_count } = this.props.person;
      this.setState({ users, users_count });
    }
  }
  

  render() {

    const { users, users_count, currentPage, perPage } = this.state;

    return (
      <TemplatePageWithNavigation title={ tr(trans.viewUsers.pageTitle) }>

        <Box>
          <BoxTitlebar title={ tr(trans.viewUsers.boxTitleManageUsers) } buttons={ [ <ButtonTitlebar key="0" title={ tr(trans.viewUsers.boxButtonAddUser) } to="/users/add" primary /> ] } />
          <div className="table">
            <div className="row header">
              <div className="cell">ID</div>
                <div className="cell">{ tr(trans.viewUsers.tableHeader.type) }</div>
                <div className="cell">{ tr(trans.viewUsers.tableHeader.name) }</div>
                <div className="cell">{ tr(trans.viewUsers.tableHeader.registered) }</div>
                <div className="cell"></div>
                <div className="cell"></div>
            </div>


              { users && users.map((user: any) => {
                return (
                  <div className="row" key={ user.user_id }>
                    <div className="cell" data-title="ID">{ user.user_email }</div>
                    <div className="cell" data-title={ tr(trans.viewUsers.tableHeader.type) }>
                      { user.user_master === true ? (
                          <span className="table-label table-label--text table-label--bg-default">{ tr(trans.viewUsers.tableLabel.admin) }</span>
                        ) : (
                          <span className="table-label table-label--text table-label--bg-custom">{ tr(trans.viewUsers.tableLabel.user) }</span>
                        ) 
                      }
                    </div>
                    <div className="cell" data-title={ tr(trans.viewUsers.tableHeader.name) }>{ user.user_title }</div>
                    <div className="cell" data-title={ tr(trans.viewUsers.tableHeader.registered) }>{ moment(user.user_registered).format('YYYY-MM-DD') }</div>
                    <div className="cell">                  
                      <Link className="table-icon" to={`/users/edit/${ user.user_id }`}>
                        <IconBoxEdit color="#666" size={24} />
                      </Link>
                    </div>

                      {
                        user.user_master === false ? (
                          <div className="cell">
                            <Link className="table-icon" to={`/users/delete/${ user.user_id }`}>
                              <IconBoxDelete color="#666" size={24} />
                            </Link>
                          </div>
                        ) : (
                          <div className="cell empty"></div>
                        )
                      }

                  </div>
                )
              })}

          </div>
          <TableFooter 
            currentPage={ currentPage }
            perPage={ perPage }
            itemsCount={ users_count }
            prevPage={() => this.prevPage(currentPage - 1)}
            nextPage={() => this.nextPage(currentPage + 1)}
            updatePagination={this.updatePagination}
          />
        </Box>

      </TemplatePageWithNavigation>


    );
  }
};


export default WithAuthentication(withWs(WithPreloader(withRouter(connect(
  ({ person }) => ({ person }),
  { fetchUsers }
)(Users)))));