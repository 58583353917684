import WithAuthentication from '../../hoc/auth/WithAuthentication';
import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import { APP_VERSION } from '../../../constants';
import { tr, trans } from '../../../translation/translations';
import withWs from '../../hoc/ws/WithWs';
import Box from '../../shared/box/Box';


const UserSettingsAbout: React.FC = () => {
  return (
    <TemplatePageWithNavigation title={ tr(trans.viewSettingsAboutApplication.pageTitle) } buttonBack>
      <Box>
        <p className="paragraph paragraph--medium paragraph--margin-medium">{ tr(trans.viewSettingsAboutApplication.paragraphCurrentApplicationVersion) }</p>
        <p className="paragraph paragraph--large paragraph--highlight">{ APP_VERSION }</p>
      </Box>
    </TemplatePageWithNavigation>
  );
};


export default WithAuthentication(withWs(UserSettingsAbout));