import React from 'react';
import WithAuthentication from '../../hoc/auth/WithAuthentication';
import { RootState } from '../../../reducers';
import { connect } from 'react-redux';
import { updateEnergyCost, getEnergyCost } from '../../../actions/auth.action';
import withRouter from '../../hoc/router/WithRouter';
import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import { tr, trans } from '../../../translation/translations';
import classnames from 'classnames';
import fetchStates from '../../../types/fetchState.types';
import { IconWarning } from '../../shared/icons/IconsForm';
import withWs from '../../hoc/ws/WithWs';
import InterfaceReducerAuth from '../../../intefaces/InterfaceReducerAuth';
import Box from '../../shared/box/Box';
import WithPreloader from '../../hoc/preloader/WithPreloader';


interface SettingsCostTariffsProps {
  auth: InterfaceReducerAuth,
  updateEnergyCost: (user_energy_cost: string) => Promise<void>,
  getEnergyCost: () => Promise<void>
}


interface SettingsCostTariffsState {
  user_energy_cost: string,
  formSubmitted: boolean,
  formError: boolean,
}


export class SettingsCostTariffsSettings extends React.Component<SettingsCostTariffsProps, SettingsCostTariffsState> {

  state = {
    user_energy_cost: '',
    formSubmitted: false,
    formError: false
  }

  componentDidMount(): void {
    this.getEnergyCost();
  }


  getEnergyCost = async () => {
    await this.props.getEnergyCost();
    if(this.props.auth.status === fetchStates.success) {
      const { user_energy_cost } = this.props.auth;
      this.setState({ user_energy_cost });
    }
  }


  handleOnEnergyChangeSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { user_energy_cost } = this.state;
    await this.props.updateEnergyCost(user_energy_cost);
    if(this.props.auth.status === fetchStates.error) {
      this.setState({ formError: true });
      const self = this;
      setTimeout(function() { 
        if(self.state.formError === true) {
          self.setState({ formError: false });
        }
      }, 250);
    }
    this.setState({ formSubmitted: false });
  }


  render() {

    const { fields, status } = this.props.auth;
    const { user_energy_cost, formSubmitted, formError } = this.state;

    return (
      <TemplatePageWithNavigation title={ tr(trans.viewSettingsCostTariffs.pageTitle) } buttonBack>
        <Box>
          <form className={classnames('form', { 'form-error': formError })} onSubmit={this.handleOnEnergyChangeSubmit}>

              <div className="form-section">
                <h2 className="form-section__title">{ tr(trans.viewSettingsCostTariffs.boxTitleEnergySettings) }</h2>
                <div className="form-group">
                  <label 
                    htmlFor="energy_cost"
                    className={classnames('label', { 'label--error': fields && fields.includes('user_energy_cost') })}
                  >
                    { fields && fields.includes('user_energy_cost') && <IconWarning size={16} color="#fff" /> } { tr(trans.viewSettingsCostTariffs.form.labelEnergyPrice) }
                  </label>
                  <div className="input-group">
                    <input 
                      type="text"
                      name="energy_cost"
                      id="energy_cost" 
                      value={ user_energy_cost }
                      onChange={e => this.setState({ user_energy_cost: e.target.value })}
                      className={classnames('input input--form', { 'input--error': fields && fields.includes('user_energy_cost') })}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <input 
                    type="submit" 
                    value={ tr(trans.viewSettingsCostTariffs.form.buttonUpdateEnergyCost) } 
                    className="button button--primary button--small"
                    disabled={ formSubmitted && status === 'fetching' ? true : false }
                  />
                </div>
              </div>
          </form>
        </Box>
      </TemplatePageWithNavigation>
    );
  }
};


export default WithAuthentication(withWs(WithPreloader(withRouter(connect(
  ({ auth }: RootState) => ({ auth }),
  { updateEnergyCost, getEnergyCost }
)(SettingsCostTariffsSettings)))));