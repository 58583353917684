import React from 'react';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { RootState } from '../../../reducers';
import fetchStates from '../../../types/fetchState.types';
import { checkTokenValid, resetPassword } from '../../../actions/auth.action';

import classnames from 'classnames';
import { IconVisibility, IconVisibilityOff, IconWarning } from '../../shared/icons/IconsForm';
import { IconBack } from '../../shared/icons/IconsNavigation';
import withRouter from '../../hoc/router/WithRouter';

import TemplatePageFullwidth from '../../shared/template-page-fullwidth/TemplatePageFullwidth';
import { IconLogoDarkDesktop } from '../../shared/icons/IconsLogo';
import Language from '../../shared/language/Language';
import { tr, trans } from '../../../translation/translations';
import StrongPasswordMeter from '../../shared/strong-password-meter/StrongPasswordMeter';
import InterfaceReducerAuth from '../../../intefaces/InterfaceReducerAuth';


interface ResetPasswordProps {
  router: {params: {reset_token_timestamp: string}, navigate: any},
  auth: InterfaceReducerAuth,
  checkTokenValid: (reset_token: string) => Promise<void>,
  resetPassword: (options: { reset_password: string, confirm_reset_password: string, reset_token: string }) => Promise<void>
}


interface ResetPasswordState {
  formSubmitted: boolean,
  reset_token: string,
  reset_token_timestamp: string,
  reset_password: string,
  confirm_reset_password: string,
  passwordShow: boolean,
  passwordConfirmShow: boolean,
  number1: string,
  number2: string,
  number3: string,
  number4: string,
  number5: string,
  number6: string,
  tokenConfirmed: boolean,
  formError: boolean
}


export class ResetPassword extends React.Component<ResetPasswordProps & ResetPasswordState> {

  public inputRef1: React.RefObject<HTMLInputElement>;
  public inputRef2: React.RefObject<HTMLInputElement>;
  public inputRef3: React.RefObject<HTMLInputElement>;
  public inputRef4: React.RefObject<HTMLInputElement>;
  public inputRef5: React.RefObject<HTMLInputElement>;
  public inputRef6: React.RefObject<HTMLInputElement>;
  constructor(props: any) {
    super(props);
    this.inputRef1 = React.createRef();
    this.inputRef2 = React.createRef();
    this.inputRef3 = React.createRef();
    this.inputRef4 = React.createRef();
    this.inputRef5 = React.createRef();
    this.inputRef6 = React.createRef();
  }
  

  state = {
    formSubmitted: false,
    reset_token_timestamp: '',
    reset_token: '',
    reset_password: '',
    confirm_reset_password: '',
    passwordShow: false,
    passwordConfirmShow: false,
    number1: '',
    number2: '',
    number3: '',
    number4: '',
    number5: '',
    number6: '',
    tokenConfirmed: false,
    formError: false
  }


  componentDidMount() {
    if(this.props.router.params.reset_token_timestamp !== undefined) {
      this.setState({ reset_token_timestamp: this.props.router.params.reset_token_timestamp });
    }
  }

  
  updateNumber1 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number1: e.target.value }, () => this.submitPIN());
      if(e.target.value.length > 5) {
        this.setState({
          number1: e.target.value[0],
          number2: e.target.value[1],
          number3: e.target.value[2],
          number4: e.target.value[3],
          number5: e.target.value[4],
          number6: e.target.value[5],
        })
      }
      if (this.inputRef2.current && e.target.value.length > 0) {
        this.inputRef2.current.focus();
        this.inputRef2.current.select();
      }
    }
  }

  
  updateNumber2 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number2: e.target.value }, () => this.submitPIN())
      if (this.inputRef3.current && e.target.value.length > 0) {
        this.inputRef3.current.focus();
        this.inputRef3.current.select();
      }
    }
  }


  updateNumber3 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number3: e.target.value }, () => this.submitPIN())
      if (this.inputRef4.current && e.target.value.length > 0) {
        this.inputRef4.current.focus();
        this.inputRef4.current.select();
      }
    }
  }


  updateNumber4 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number4: e.target.value }, () => this.submitPIN())
      if (this.inputRef5.current && e.target.value.length > 0) {
        this.inputRef5.current.focus();
        this.inputRef5.current.select();
      }
    }
  }


  updateNumber5 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number5: e.target.value }, () => this.submitPIN())
      if (this.inputRef6.current && e.target.value.length > 0) {
        this.inputRef6.current.focus();
        this.inputRef6.current.select();
      }
    }
  }


  updateNumber6 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number6: e.target.value }, () => this.submitPIN());
    }
  }

  clearNumber2 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef1.current && e.target.value.length === 0) {
        this.inputRef1.current.focus();
        this.inputRef1.current.select();
      }
    }
  }

  clearNumber3 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef2.current && e.target.value.length === 0) {
        this.inputRef2.current.focus();
        this.inputRef2.current.select();
      }
    }
  }

  clearNumber4 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef3.current && e.target.value.length === 0) {
        this.inputRef3.current.focus();
        this.inputRef3.current.select();
      }
    }
  }

  clearNumber5 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef4.current && e.target.value.length === 0) {
        this.inputRef4.current.focus();
        this.inputRef4.current.select();
      }
    }
  }


  clearNumber6 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef5.current && e.target.value.length === 0) {
        this.inputRef5.current.focus();
        this.inputRef5.current.select();
      }
    }
  }


  submitPIN = () => {
    if(
      this.state.number1 !== '' &&
      this.state.number2 !== '' &&
      this.state.number3 !== '' &&
      this.state.number4 !== '' &&
      this.state.number5 !== '' &&
      this.state.number6 !== ''
    ) {
      const { number1, number2, number3, number4, number5, number6 } = this.state;
      const reset_token = number1.toString() + number2.toString() + number3.toString() + number4.toString() + number5.toString() + number6.toString();
      this.setState({ reset_token });
      this.props.checkTokenValid(reset_token)
      .then(() => {
        if(this.props.auth.status === fetchStates.success) {
          this.setState({ tokenConfirmed: true });
        }
        if(this.props.auth.status === fetchStates.error) {
          this.setState({ formError: true });
          const self = this;
          setTimeout(function() { 
            if(self.state.formError === true) {
              self.setState({ formError: false }) ;
            }
          }, 250);
        }
      });
    }
  }


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { reset_password, confirm_reset_password, reset_token } = this.state;
    await this.props.resetPassword({ reset_password, confirm_reset_password, reset_token });
    if(this.props.auth.status === 'success') {
      this.setState({ tokenConfirmed: false, reset_password: '', confirm_reset_password: '', reset_token: '', formSubmitted: false });
      this.props.router.navigate('/');
    }
    if(this.props.auth.status === fetchStates.error) {
      this.setState({ formError: true });
      const self = this;
      setTimeout(function() { 
        if(self.state.formError === true) {
          self.setState({ formError: false }) ;
        }
      }, 250);
    }
  }


  render() {

    const { formSubmitted, reset_password, confirm_reset_password, passwordShow, passwordConfirmShow, number1, number2, number3, number4, number5, number6, tokenConfirmed, formError } = this.state;
    const { authenticated, fields, status } = this.props.auth;
    
    return authenticated ? <Navigate to="/" /> : (
        <TemplatePageFullwidth>
          <Language />
          <div className="page page--auth page__forgot-password">
          <div className="auth-back-button">
            <Link to="/">
              <IconBack color="#000" size={40} />
            </Link>
          </div>
          <div className={classnames('form-wrapper form-wrapper--auth', { 'form-error': formError })}>
            <div className="logo logo--auth">
              <IconLogoDarkDesktop width={240} height={80} />
            </div>
            <h1 className="title title--main title--auth">{ tr(trans.viewPasswordReset.pageTitle) }</h1>
              { tokenConfirmed === false ? (
                <>
                  <p className="paragraph paragraph--auth center">{ tr(trans.viewPasswordReset.paragraphInstructions) }</p>
                  <form className="form form--auth" onSubmit={this.handleOnSubmit}>
                  <div className="auth-pins-wrapper">
                    <div className="form-group form-group--auth auth-pins">
                      <input
                        id="number1"
                        type="test"
                        value={number1}
                        ref={this.inputRef1} 
                        onChange={e => this.updateNumber1(e)}
                        className={classnames('auth-pin', { 'auth-pin--error': fields && fields.includes('reset_pin') })}
                      />
                      <input
                        id="number2"
                        type="test"
                        value={number2}
                        ref={this.inputRef2} 
                        onChange={e => this.updateNumber2(e)}
                        onKeyDown={e => this.clearNumber2(e)}
                        className={classnames('auth-pin', { 'auth-pin--error': fields && fields.includes('reset_pin') })}
                      />
                      <input
                        id="number3"
                        type="test"
                        value={number3}
                        ref={this.inputRef3} 
                        onChange={e => this.updateNumber3(e)}
                        onKeyDown={e => this.clearNumber3(e)}
                        className={classnames('auth-pin', { 'auth-pin--error': fields && fields.includes('reset_pin') })}
                      />
                      <input
                        id="number4"
                        type="test"
                        value={number4}
                        ref={this.inputRef4} 
                        onChange={e => this.updateNumber4(e)}
                        onKeyDown={e => this.clearNumber4(e)}
                        className={classnames('auth-pin', { 'auth-pin--error': fields && fields.includes('reset_pin') })}
                      />
                      <input
                        id="number5"
                        type="test"
                        value={number5}
                        ref={this.inputRef5} 
                        onChange={e => this.updateNumber5(e)}
                        onKeyDown={e => this.clearNumber5(e)}
                        className={classnames('auth-pin', { 'auth-pin--error': fields && fields.includes('reset_pin') })}
                      />
                      <input
                        id="number6"
                        type="test"
                        value={number6}
                        ref={this.inputRef6} 
                        onChange={e => this.updateNumber6(e)}
                        onKeyDown={e => this.clearNumber6(e)}
                        className={classnames('auth-pin', { 'auth-pin--error': fields && fields.includes('reset_pin') })}
                      />
                    </div>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <p className="paragraph paragraph--auth">{ tr(trans.viewPasswordReset.paragraphInstructions) }</p>
                  <form className="form form--auth" onSubmit={this.handleOnSubmit}>
                    <div className="form-group form-group--auth">
                      <label className={classnames('label label--auth', { 'label--error': fields && fields.includes('reset_password') })} htmlFor="login__email">
                        { fields && fields.includes('reset_password') && <IconWarning size={16} color="#fff" /> } 
                        { tr(trans.viewPasswordReset.form.labelNewPassword) }
                      </label>

                      <div className="input-group input-group--auth">
                        <span className="password-reveal" onClick={e => this.setState({ passwordShow: !passwordShow })}>
                          { passwordShow === false ? (
                            <IconVisibility size={18} color="#000" />
                          ) : (
                            <IconVisibilityOff size={18} color="#000" />
                          )}
                        </span>
                        <input
                          id="password"
                          type={ passwordShow === false ? 'password' : 'text' } 
                          autoComplete="password"
                          value={reset_password}
                          className={classnames('input input--auth', { 'input--error': fields && fields.includes('reset_password') })}
                          onChange={e => this.setState({ reset_password: e.target.value })}
                        />
                        <StrongPasswordMeter password={ reset_password } />
                      </div>

                    </div>

                    <div className="form-group form-group--auth">
                    <label className={ classnames('label label--auth', { 'label--error': fields && fields.includes('confirm_reset_password') }) } htmlFor="login__email">
                      { fields && fields.includes('confirm_reset_password') && <IconWarning size={16} color="#fff" /> } 
                      { tr(trans.viewPasswordReset.form.labelNewPasswordConfirmation) }
                    </label>
                      <div className="input-group input-group--auth">
                        <span className="password-reveal" onClick={e => this.setState({ passwordConfirmShow: !passwordConfirmShow })}>
                          { passwordConfirmShow === false ? (
                            <IconVisibility size={18} color="#000" />
                          ) : (
                            <IconVisibilityOff size={18} color="#000" />
                          )}
                        </span>
                        <input
                          id="confirm_password"
                          type={ passwordConfirmShow === false ? 'password' : 'text' } 
                          autoComplete="current-password"
                          value={ confirm_reset_password }
                          className={classnames('input input--auth', { 'input--error': fields && fields.includes('confirm_reset_password') })}
                          onChange={e => this.setState({ confirm_reset_password: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="form-group form-group--auth">
                      <input 
                        type="submit" 
                        className="button button--auth button--large button-margin--bottom" 
                        value={ tr(trans.viewPasswordReset.form.buttonUpdateMyPassword) } 
                        disabled={formSubmitted && status === 'fetching' ? true : false}
                      />
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </TemplatePageFullwidth>
      );
    }
  };
  
  
  export default withRouter(connect(
    ({ auth }: RootState) => ({ auth }),
    { checkTokenValid, resetPassword }
  )(ResetPassword));