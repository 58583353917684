const APP_VERSION = '0.2.7';
const COOKIES_LANGUAGE_EXPIRE_DAYS = 100000;
const COOKIES_LANGUAGE_DEFAULT = 'en';
const GMAP_API_KEY = 'AIzaSyCvO6PqaYZ36xb6q4snZnxUf-zZDYi7H_U';


const USER_AUTHORIZED = {
  'manage_users': false,
  'manage_events': false,
  'manage_groups': false,
  'manage_devices': false,
  'manage_energy_prices': false
}


export { 
  APP_VERSION,
  COOKIES_LANGUAGE_EXPIRE_DAYS,
  COOKIES_LANGUAGE_DEFAULT,
  GMAP_API_KEY,
  USER_AUTHORIZED
};