import React from 'react';
import WithAuthentication from '../../hoc/auth/WithAuthentication';
import withRouter from '../../hoc/router/WithRouter';
import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import { tr, trans } from '../../../translation/translations';
import languageTranslations from '../../../functions/languatgeTranslations';
import getCookie from '../../../functions/cookieGet';
import setCookie from '../../../functions/cookieSet';
import { COOKIES_LANGUAGE_DEFAULT, COOKIES_LANGUAGE_EXPIRE_DAYS } from '../../../constants';
import withWs from '../../hoc/ws/WithWs';
import InterfaceReducerAuth from '../../../intefaces/InterfaceReducerAuth';
import Box from '../../shared/box/Box';
import WithPreloader from '../../hoc/preloader/WithPreloader';


interface SettingsApplicationProps {
  auth: InterfaceReducerAuth,
}


interface SettingsApplicationState {
  language: string,
  formSubmitted: boolean,
  formError: boolean,
}


export class SettingsApplication extends React.Component<SettingsApplicationProps, SettingsApplicationState> {

  state = {
    language: 'en',
    languageTranslations,
    formSubmitted: false,
    formError: false
  }

  componentDidMount(): void {
    if(!getCookie('acenergyLanguage')) {
      setCookie('acenergyLanguage', COOKIES_LANGUAGE_DEFAULT, COOKIES_LANGUAGE_EXPIRE_DAYS);
    } else {
      this.setState({ language: getCookie('acenergyLanguage') });
    }
  }


  setLanguage = (language: string) => {
    setCookie('acenergyLanguage', language, COOKIES_LANGUAGE_EXPIRE_DAYS);
    this.setState({ language });
    window.location.reload();
  }


  render() {

    const { language, languageTranslations } = this.state;

    return (
      <TemplatePageWithNavigation title={ tr(trans.viewSettingsApplicationSettings.pageTitle) } buttonBack>
        <Box>
          <form className="form">
            <div className="form-section">
              <h2 className="form-section__title">{ tr(trans.viewSettingsApplicationSettings.boxTitleLanguageSettings) }</h2>
              <div className="form-group">
                <label 
                  htmlFor="language"
                  className="label"
                >
                  { tr(trans.viewSettingsApplicationSettings.form.labelLanguage) }
                </label>
                <div className="input-group">
                  <select 
                    name="language" 
                    value={language}
                    onChange={ (e:any) => this.setLanguage(e.target.value) }
                    className="input input--form"
                  >
                    {
                      languageTranslations.map((language, index) => (
                        <option key={index} value={language.shortcut}>{language.title}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </div>
          </form>
        </Box>
      </TemplatePageWithNavigation>
    );
  }
};


export default WithAuthentication(withWs(WithPreloader(withRouter((SettingsApplication)))));