import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchGroupsOverview } from '../../../actions/group.action';

import WithAuthentication from '../../hoc/auth/WithAuthentication';
import withRouter from '../../hoc/router/WithRouter';

import { tr, trans } from '../../../translation/translations';
import InterfaceReducerGroup from '../../../intefaces/InterfaceReducerGroup';
import Box from '../box/Box';
import BoxTitlebar from '../box-titlebar/BoxTitlebar';
import TableFooter from '../table-footer/TableFooter';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IconTablesMore } from '../icons/IconsTables';
import getLocalStorageItem from '../../../functions/getLocalStorageItem';




interface DashboardOverviewProps {
  router: { location: string, navigate: (to: any) => any },
  group: InterfaceReducerGroup,
  fetchGroupsOverview: (options: { limit: number, skip: number }) => Promise<void>,
  wsConnected: boolean,
  wsData: any,
  dataCommandEmit: any
}


interface DashboardOverviewState {
  groups_overview: Array<any>,
  groups_overview_count: number,
  currentPage: number,
  perPage: number,
}


export class DashboardOverview extends React.Component<DashboardOverviewProps, DashboardOverviewState> {


  state = {
    groups_overview: [],
    groups_overview_count: 0,
    perPage: 5,
    currentPage: 0,
  }


  componentDidMount(): void {
    this.getAllWallboxDevices();
  }


  getAllWallboxDevices = async () => {
    const { perPage, currentPage } = this.state;
    this.reloadPage({ perPage, currentPage });
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  });
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  });
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage });
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage });
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage });
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage });
  }


  reloadPage = async (options: { perPage: number, currentPage: number }) => {
    await this.props.fetchGroupsOverview({ limit: options.perPage, skip: options.perPage * options.currentPage });
    if(this.props.group.status === fetchStates.success) {
      const { groups_overview, groups_overview_count } = this.props.group;
      this.setState({ groups_overview, groups_overview_count });
    }
  }
  

  render() {

    const { groups_overview, groups_overview_count, perPage, currentPage } = this.state;

    return (
      <Box>
        <BoxTitlebar title={ tr(trans.partialDashboardOverview.partialTitle) } />
        <div className="table">
              <div className="row header">
                <div className="cell">{ tr(trans.partialDashboardOverview.tableHeader.groupName) }</div>
                <div className="cell">{ tr(trans.partialDashboardOverview.tableHeader.wallboxCount) }</div>
                <div className="cell">{ tr(trans.partialDashboardOverview.tableHeader.activeWallbox) }</div>
                <div className="cell">{ tr(trans.partialDashboardOverview.tableHeader.energyConsumed) }</div>
                <div className="cell">{ tr(trans.partialDashboardOverview.tableHeader.eventsErrors) }</div>
                <div className="cell">{ tr(trans.partialDashboardOverview.tableHeader.energyCost) }</div>
                <div className="cell">{ tr(trans.partialDashboardOverview.tableHeader.registeredUsers) }</div>
                <div className="cell"></div>
              </div>


            { groups_overview && groups_overview.map((group: any) => {
                return (
                  <div className="row" key={ group.group_serial }>
                    <div className="cell" data-title={ tr(trans.partialDashboardOverview.tableHeader.groupName) }>
                      { group.group_title }
                    </div>
                    <div className="cell" data-title={ tr(trans.partialDashboardOverview.tableHeader.wallboxCount) }>
                      12
                    </div>
                    <div className="cell" data-title={ tr(trans.partialDashboardOverview.tableHeader.activeWallbox) }>
                      3
                    </div>
                    <div className="cell" data-title={ tr(trans.partialDashboardOverview.tableHeader.energyConsumed) }>
                      259 kWh
                    </div>
                    <div className="cell" data-title={ tr(trans.partialDashboardOverview.tableHeader.eventsErrors) }>
                      0/0
                    </div>
                    <div className="cell" data-title={ tr(trans.partialDashboardOverview.tableHeader.energyCost) }>
                      { 259 * getLocalStorageItem('user_energy_cost') } { getLocalStorageItem('user_currency_format') }
                    </div>
                    <div className="cell" data-title={ tr(trans.partialDashboardOverview.tableHeader.registeredUsers) }>
                      22
                    </div>
                    <div className="cell">
                      <Link className="table-icon" to={`/overview/group/${ group.group_id }`}>
                        <IconTablesMore color="#666" size={24} />
                      </Link>
                    </div>
                  </div>
                );
            })}

          </div>
          <TableFooter 
            currentPage={ currentPage }
            perPage={ perPage }
            itemsCount={ groups_overview_count }
            prevPage={() => this.prevPage(currentPage - 1)}
            nextPage={() => this.nextPage(currentPage + 1)}
            updatePagination={this.updatePagination}
          />

        {
          /*
            <div className="box-body">
              <div className="table">
                <div className="row header">
                  <div className="cell">Group Name</div>
                  <div className="cell">Wallbox Count</div>
                  <div className="cell">Active Wallbox</div>
                  <div className="cell">Energy consumed</div>
                  <div className="cell">Events/Errors</div>
                  <div className="cell">Energy Cost [EUR]</div>
                  <div className="cell">Number of registered users</div>
                </div>
                <div className="row">
                  <div className="cell" data-title="Group Name">Green Corner</div>
                  <div className="cell" data-title="Wallbox">12</div>
                  <div className="cell" data-title="Active Wallbox">5</div>
                  <div className="cell" data-title="Energy consumed">234</div>
                  <div className="cell" data-title="Events/Errors">0</div>
                  <div className="cell" data-title="Energy Cost [EUR]">5673</div>
                  <div className="cell" data-title="Number of registered users">24</div>
                </div>
                <div className="row">
                  <div className="cell" data-title="Group Name">Strawberry Street</div>
                  <div className="cell" data-title="Wallbox">45</div>
                  <div className="cell" data-title="Active Wallbox">28</div>
                  <div className="cell" data-title="Energy consumed">1456</div>
                  <div className="cell" data-title="Events/Errors">1</div>
                  <div className="cell" data-title="Energy Cost [EUR]">18764</div>
                  <div className="cell" data-title="Number of registered users">76</div>
                </div>
                <div className="row">
                  <div className="cell" data-title="Group Name">Green Corner</div>
                  <div className="cell" data-title="Wallbox">12</div>
                  <div className="cell" data-title="Active Wallbox">5</div>
                  <div className="cell" data-title="Energy consumed">234</div>
                  <div className="cell" data-title="Events/Errors">0</div>
                  <div className="cell" data-title="Energy Cost [EUR]">5673</div>
                  <div className="cell" data-title="Number of registered users">24</div>
                </div>
                <div className="row">
                  <div className="cell" data-title="Group Name">Strawberry Street</div>
                  <div className="cell" data-title="Wallbox">45</div>
                  <div className="cell" data-title="Active Wallbox">28</div>
                  <div className="cell" data-title="Energy consumed">1456</div>
                  <div className="cell" data-title="Events/Errors">1</div>
                  <div className="cell" data-title="Energy Cost [EUR]">18764</div>
                  <div className="cell" data-title="Number of registered users">76</div>
                </div>
              </div>
            </div>
          */
        }

      </Box>
    );
  }
};


export default WithAuthentication(withRouter(connect(
  ({ group }) => ({ group }),
  { fetchGroupsOverview }
)(DashboardOverview)));