import React from 'react';
import Box from '../../shared/box/Box';
import Map from '../../shared/map/Map';


const DashboardInfo: React.FC = () => {
  return (
    <Box>
      <div className="box-body">
        <div className="charger-settings">

          <div className="charger-settings__section charger-settings__section--charger-settings">
            <div className="charger-settings__section-title">Charger Settings</div>
            <div className="charger-settings__item"><strong>S/N:</strong> 02</div>
            <div className="charger-settings__item"><strong>Name:</strong> Easton Cox</div>
            <div className="charger-settings__item"><strong>Connection Power:</strong> Full</div>
            <div className="charger-settings__item"><strong>Name:</strong> Device</div>
          </div>


          <div className="charger-settings__section charger-settings__section--map">
            <div className="charger-settings__section-title">Charger Location</div>
            <Map />
          </div>


          <div className="charger-settings__section charger-settings__section--user">
            <div className="charger-settings__section-title">Users</div>
            <div className="charger-settings__item">
              <a href="/">adam.nowak@gmail.com</a>
            </div>
            <div className="charger-settings__item">
              <a href="/">a.sadowski@gmail.com</a>
            </div>
            <div className="charger-settings__item">
              <a href="/">z.song@gmail.com</a>
            </div>
          </div>

        </div>

      </div>
    </Box>
  );
};


export default DashboardInfo;