import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { GMAP_API_KEY } from '../../../constants';
import './map.css';


const Map: React.FC = () => {
  return (
    <LoadScript googleMapsApiKey={GMAP_API_KEY}>
      <GoogleMap
        mapContainerClassName="map-container"
        center={{ lat: -34.397, lng: 150.644 }}
        zoom={8}
      />
    </LoadScript>
  );
}


export default Map;