import React, { Component } from "react";
import Chart from "react-apexcharts";

class App extends Component {
  state = {
    options: {
      chart: {
        toolbar: {
          show: false,
          width: '10%'
        }
      },
      xaxis: {
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        tooltip: {
          enabled: false
        }
      },
      colors: ["#17B566"],
      dataLabels: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      legend: {
        show: false
      },
      plotOptions: {
        bar: {
          columnWidth: '12px',
          borderRadius: 4,
        }
      },
      yaxis: {
        labels: {
          formatter: function (value:any) {
            return value + "w";
          }
        },
        tooltip: {
          enabled: false
        }
      },
    },

    series: [
      {
        data: [38, 45, 32, 46, 35, 29, 25]
      }
    ]
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default App;