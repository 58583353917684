import React from 'react';
import WithAuthentication from '../../hoc/auth/WithAuthentication';
import { RootState } from '../../../reducers';
import { connect } from 'react-redux';
import { changePassword } from '../../../actions/auth.action';
import { tr, trans } from '../../../translation/translations';
import { IconVisibility, IconVisibilityOff, IconWarning } from '../../shared/icons/IconsForm';
import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import fetchStates from '../../../types/fetchState.types';
import withRouter from '../../hoc/router/WithRouter';
import classnames from 'classnames';
import StrongPasswordMeter from '../../shared/strong-password-meter/StrongPasswordMeter';
import withWs from '../../hoc/ws/WithWs';
import InterfaceReducerAuth from '../../../intefaces/InterfaceReducerAuth';
import Box from '../../shared/box/Box';
import WithPreloader from '../../hoc/preloader/WithPreloader';



interface SettingsChangePasswordProps {
  router: { location: string, navigate: (to: string) => any },
  auth: InterfaceReducerAuth,
  changePassword: (options: { password_confirmation: string, password_old: string, password_new: string }) => Promise<void>,
}


interface SettingsChangePasswordState {
  formSubmitted: boolean,
  formError: boolean,
  password_confirmation: string,
  password_old: string,
  password_new: string,
  passwordShow: boolean,
  passwordOldShow: boolean,
  passwordConfirmationShow: boolean,
}



export class SettingsChangePassword extends React.Component<SettingsChangePasswordProps, SettingsChangePasswordState> {

  state = {
    formSubmitted: false,
    formError: false,
    password_confirmation: '',
    password_old: '',
    password_new: '',
    passwordShow: false,
    passwordOldShow: false,
    passwordConfirmationShow: false,
  }


  handleOnPaswordChangeSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { password_confirmation, password_old, password_new } = this.state;
    await this.props.changePassword({ password_confirmation, password_old, password_new });
    if(this.props.auth.status === fetchStates.success) {
      this.setState({ 
        password_confirmation: '', 
        password_old: '', 
        password_new: ''
      });
    }
    if(this.props.auth.status === fetchStates.error) {
      this.setState({ formError: true });
      const self = this;
      setTimeout(function() { 
        if(self.state.formError === true) {
          self.setState({ formError: false }) ;
        }
      }, 250);
    }
  }


  render() {

    const { status, fields } = this.props.auth;
    const { passwordShow, passwordConfirmationShow, passwordOldShow, password_confirmation, password_old, password_new, formSubmitted, formError } = this.state;

    return (
      <TemplatePageWithNavigation title={ tr(trans.viewSettingsChangePassword.pageTitle) } buttonBack>
        <Box>

            <form className={classnames('form', { 'form-error': formError })} onSubmit={this.handleOnPaswordChangeSubmit}>
                <div className="form-group">
                  <label 
                    htmlFor="password_old"
                    className={classnames('label', { 'label--error': fields && fields.includes('password_old') })}
                  >
                    { fields && fields.includes('password_old') && <IconWarning size={16} color="#fff" /> } { tr(trans.viewSettingsChangePassword.form.labelOldPassword) } <strong>({ tr(trans.form.required) })</strong>
                  </label>
                  <div className="input-group">
                    <span className="password-reveal" onClick={e => this.setState({ passwordOldShow: !passwordOldShow })}>
                      { passwordOldShow === false ? (
                        <IconVisibility color="#000" size={18} />
                      ) : (
                        <IconVisibilityOff color="#000" size={18} />
                      )}
                    </span>
                    <input 
                      type={ passwordOldShow === false ? 'password' : 'text' } 
                      name="password_old"
                      id="password_old" 
                      value={ password_old }
                      onChange={e => this.setState({ password_old: e.target.value })}
                      className={classnames('input input--form', { 'input--error': fields && fields.includes('password_old') })}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label 
                    htmlFor="password_new"
                    className={classnames('label', { 'label--error': fields && fields.includes('password_new') })}
                  >
                  { fields && fields.includes('password_new') && <IconWarning size={16} color="#fff" /> } { tr(trans.viewSettingsChangePassword.form.labelNewPassword) } <strong>({ tr(trans.form.required) })</strong>
                  </label>
                  <div className="input-group">
                    <span className="password-reveal" onClick={e => this.setState({ passwordShow: !passwordShow })}>
                      { passwordShow === false ? (
                        <IconVisibility color="#000" size={18} />
                      ) : (
                        <IconVisibilityOff color="#000" size={18} />
                      )}
                    </span>
                    <input 
                      type={ passwordShow === false ? 'password' : 'text' } 
                      name="password_new"
                      id="password_new" 
                      value={ password_new }
                      onChange={e => this.setState({ password_new: e.target.value })}
                      className={classnames('input input--form', { 'input--error': fields && fields.includes('password_new') })}
                    />
                    <StrongPasswordMeter password={ password_new } />
                  </div>
                </div>

                <div className="form-group">
                  <label 
                    htmlFor="password_confirmation"
                    className={classnames('label', { 'label--error': fields && fields.includes('password_confirmation') })}
                  >
                    { fields && fields.includes('password_confirmation') && <IconWarning size={16} color="#fff" /> }{ tr(trans.viewSettingsChangePassword.form.labelNewPasswordConfirmation) } <strong>({ tr(trans.form.required) })</strong>
                  </label>
                  <div className="input-group">
                    <span className="password-reveal" onClick={e => this.setState({ passwordConfirmationShow: !passwordConfirmationShow })}>
                      { passwordConfirmationShow === false ? (
                        <IconVisibility color="#000" size={18} />
                      ) : (
                        <IconVisibilityOff color="#000" size={18} />
                      )}
                    </span>
                    <input 
                      type={ passwordConfirmationShow === false ? 'password' : 'text' } 
                      name="password_confirmation"
                      id="password_confirmation" 
                      value={ password_confirmation }
                      onChange={e => this.setState({ password_confirmation: e.target.value })}
                      className={classnames('input input--form', { 'input--error': fields && fields.includes('password_confirmation') })}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input 
                    type="submit" 
                    value={ tr(trans.viewSettingsChangePassword.form.buttonChangePassword) } 
                    className="button button--primary button--large"
                    disabled={ formSubmitted && status === 'fetching' ? true : false }
                  />
                </div>
            </form>
        </Box>
      </TemplatePageWithNavigation>
    )
  }
};


export default WithAuthentication(withWs(WithPreloader(withRouter(connect(
  ({ auth }: RootState) => ({ auth }),
  { changePassword }
)(SettingsChangePassword)))));