import React from 'react';
import Box from '../../shared/box/Box';
import BoxTitlebar from '../box-titlebar/BoxTitlebar';
import ChartEnergy from '../charts/ChartEnergy';
import ButtonTitlebar from '../button-titlebar/ButtonTitlebar';


const DashboardCharts: React.FC = () => {
  return (
    <Box>
    <BoxTitlebar title="Charts" buttons={ [ <ButtonTitlebar key="0" title="Now" />, <ButtonTitlebar key="1" title="Today" />, <ButtonTitlebar key="2" title="Week" selected />, <ButtonTitlebar key="3" title="Month" />, <ButtonTitlebar key="4" title="Year" /> ] } />
    <div className="box-body">
      <div className="dashboard-charts">
        <div className="dashboard-chart">
          <div className="dashboard-chart__title"><strong>Energy:</strong> 2345[kWh]</div>
          <ChartEnergy />
        </div>
        <div className="dashboard-chart">
          <div className="dashboard-chart__title"><strong>Cost:</strong> 1234[EUR]</div>
          <ChartEnergy />
        </div>
        <div className="dashboard-chart">
          <div className="dashboard-chart__title"><strong>Time:</strong> 123[h]</div>
          <ChartEnergy />
        </div>
      </div>
    </div>
    </Box>
  )
};


export default DashboardCharts;