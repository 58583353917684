import WithAuthentication from '../../hoc/auth/WithAuthentication';
import withWs from '../../hoc/ws/WithWs';
import { tr, trans } from '../../../translation/translations';

import './dashboard.css';
import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import DashboardOverview from '../../shared/dashboard-overview/DashboardOverview';
import DashboardCharts from '../../shared/dashboard-charts/DashboardCharts';
import DashboardChargingEvents from '../../shared/dashboard-charging-events/DashboardChargingEvents';
import DashboardInfo from '../../shared/dashboard-info/DashboardInfo';


const Home: React.FC = () => {
  return (
      <TemplatePageWithNavigation title={tr(trans.viewDashboard.pageTitle)}>
        <div className="dashboard">


          <div className="dashboard-section">
            <DashboardOverview />
            <DashboardCharts />
            <DashboardChargingEvents />
          </div>


          <div className="dashboard-section">
            <DashboardInfo />
          </div>


        </div>
      </TemplatePageWithNavigation>
  );
};


export default WithAuthentication(withWs(Home));