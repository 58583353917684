import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { addDeviceWallbox } from '../../../actions/device.action';
import { fetchAllAccountGroups } from '../../../actions/group.action';

import WithAuthentication from '../../hoc/auth/WithAuthentication';
import withRouter from '../../hoc/router/WithRouter';

import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import { tr, trans } from '../../../translation/translations';
import withWs from '../../hoc/ws/WithWs';
import InterfaceReducerDevice from '../../../intefaces/InterfaceReducerDevice';
import InterfaceReducerGroup from '../../../intefaces/InterfaceReducerGroup';
import Box from '../../shared/box/Box';
import classnames from 'classnames';
import { IconWarning } from '../../shared/icons/IconsForm';
import WithPreloader from '../../hoc/preloader/WithPreloader';




interface DeviceAddProps {
  router: { location: string, navigate: (to: any) => any, params: { device_id: string } },
  device: InterfaceReducerDevice,
  group: InterfaceReducerGroup
  addDeviceWallbox: (device_wallbox: any) => Promise<void>,
  fetchAllAccountGroups: () => Promise<any>,
}


interface DeviceAddState {
  device_wallbox: {
    device_title: string,
    device_serial: string,
    group_id: string
  },
  groups_account: Array<any>,
  formSubmitted: boolean,
  formError: boolean
}


export class DeviceAdd extends React.Component<DeviceAddProps, DeviceAddState> {


  state = {
    device_wallbox: {
      device_title: '',
      device_serial: '',
      group_id: ''
    },
    groups_account: [],
    formSubmitted: false,
    formError: false
  }


  componentDidMount(): void {
    this.getAllAccountGroups();
  }


  getAllAccountGroups = async () => {
    await this.props.fetchAllAccountGroups();
    if(this.props.group.status === fetchStates.success) {
      const { groups_account } = this.props.group;
      this.setState({ groups_account, device_wallbox: { ...this.state.device_wallbox, group_id: groups_account[0].group_id } });
    }
  }


  handleOnDeviceAddSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    await this.props.addDeviceWallbox(this.state.device_wallbox);
    this.setState({ formSubmitted: false });
    if(this.props.device.status === fetchStates.error) {
      this.setState({ formError: true });
      const self = this;
      setTimeout(function() { 
        if(self.state.formError === true) {
          self.setState({ formError: false });
        }
      }, 250);
    }
    if(this.props.device.status === fetchStates.success) {
      this.props.router.navigate('/devices');
    }
    this.setState({ formSubmitted: false });
  }
  

  render() {

    const { device_wallbox, groups_account, formSubmitted, formError } = this.state;
    const { fields, status } = this.props.device;

    return (
      <TemplatePageWithNavigation title={ tr(trans.viewDevicesWallboxAdd.pageTitle) } buttonBack>
        <Box>
          <form className={classnames('form', { 'form-error': formError })} onSubmit={this.handleOnDeviceAddSubmit}>
            <div className="form-section">

              <div className="form-group">
                <label 
                  htmlFor="device_title"
                  className={classnames('label', { 'label--error': fields && fields.includes('device_wallbox.device_title') })}
                >
                    { fields && fields.includes('device_wallbox.device_title') && <IconWarning size={16} color="#fff" /> } { tr(trans.viewDevicesWallboxAdd.form.labelDeviceTitle) }
                </label>
                <div className="input-group">
                  <input 
                      type="text"
                      name="device_title"
                      id="device_title" 
                      value={ device_wallbox.device_title }
                      onChange={e => this.setState({ device_wallbox: { ...device_wallbox, device_title: e.target.value } })}
                      className={classnames('input input--form', { 'input--error': fields && fields.includes('device_wallbox.device_title') })}
                    />
                </div>
              </div>

              <div className="form-group">
                <label 
                  htmlFor="device_serial"
                  className={classnames('label', { 'label--error': fields && fields.includes('device_wallbox.device_serial') })}
                >
                    { fields && fields.includes('device_wallbox.device_serial') && <IconWarning size={16} color="#fff" /> } { tr(trans.viewDevicesWallboxAdd.form.labelDeviceSerialNumber) }
                </label>
                <div className="input-group">
                  <input 
                      type="text"
                      name="device_serial"
                      id="device_serial" 
                      value={ device_wallbox.device_serial }
                      onChange={e => this.setState({ device_wallbox: { ...device_wallbox, device_serial: e.target.value.toUpperCase() } })}
                      className={classnames('input input--form', { 'input--error': fields && fields.includes('device_wallbox.device_serial') })}
                    />
                </div>
              </div>


              <div className="form-group">
                <label 
                  htmlFor="group_id"
                  className={classnames('label', { 'label--error': fields && fields.includes('device_wallbox.group_id') })}
                >
                    { fields && fields.includes('device_wallbox.group_id') && <IconWarning size={16} color="#fff" /> } { tr(trans.viewDevicesWallboxAdd.form.labelDeviceGroupId) }
                </label>
                <div className="input-group">
                  <select 
                    name="group_id" 
                    value={ device_wallbox.group_id } 
                    onChange={e => this.setState({ device_wallbox: { ...device_wallbox, group_id: e.target.value } })}
                    className={classnames('select select--form', { 'select--error': fields && fields.includes('device_wallbox.group_id') })}
                  > 
                    {
                      groups_account && groups_account.map((item: {group_id: string, group_title: string}, index: number) => (
                        <option key={index} value={item.group_id}>{item.group_title}</option>
                      ))
                    }
                  </select>
                </div>
              </div>


              <div className="form-group">
                <input 
                  type="submit" 
                  value={ tr(trans.viewDevicesWallboxAdd.form.buttonAddDevice) } 
                  className="button button--primary button--small"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>

            </div>
          </form>
        </Box>
      </TemplatePageWithNavigation>
    );
  }
};


export default WithAuthentication(withWs(WithPreloader(withRouter(connect(
  ({ device, group }) => ({ device, group }),
  { addDeviceWallbox, fetchAllAccountGroups }
)(DeviceAdd)))));