import React from 'react';
import './with-ws.css';

import { io } from 'socket.io-client';

import classnames from 'classnames';



let SOCKET: any;
let SOCKET_ID: string;


const withWs = (Component: any) => {



  interface withWsProps {
  }


  interface withWsState {
    authenticated: boolean,
    wsConnected: boolean,
    wsData: any
  }


  class WithWs extends React.Component<withWsProps, withWsState> {

    state = {
      authenticated: (String(localStorage.getItem('authenticated')).toLowerCase() === 'true') || false,
      wsConnected: false,
      wsData: {}
    }


    componentDidMount = (): void => {
      if(this.state.authenticated) {
        if(SOCKET) {
          this.serveWS();
        } else {
          this.connectWS();
          this.serveWS();
        }
      } else {
        if(SOCKET) {
          SOCKET.disconnect();
        }
      }
    }


    connectWS = () => {
      console.log(`[INFO]: app is connecting to the following WS address: ${process.env.REACT_APP_WS_PROTOCOL}://${process.env.REACT_APP_WS_DOMAIN}:${process.env.REACT_APP_WS_PORT_DIST}`)
      SOCKET = io(`${process.env.REACT_APP_WS_PROTOCOL}://${process.env.REACT_APP_WS_DOMAIN}:${process.env.REACT_APP_WS_PORT_DIST}`, { transports: ["websocket"] });
    }


    serveWS = () => {
      if(SOCKET) {

        if(SOCKET.connected) {
          this.setState({ wsConnected: true });
        }

        // SOCKET WS CONNECTED
        SOCKET.on("connect", () => {
          SOCKET_ID = SOCKET.id;
          this.setState({ wsConnected: true });
          console.log('[INFO]: connected to ws ID:', SOCKET_ID);
        });
      

        // SOCKET WS DISCONNECTED
        SOCKET.on("disconnect", () => {
          console.log('DISCONNECT', SOCKET_ID, SOCKET.id)
          this.setState({ wsConnected: false });
          console.log('[DEBUG]: disconnected from socket ID:', SOCKET_ID); // undefined
        });
      
        
        // SOCKET ON RECEIVING DATA
        SOCKET.on("data", (message: string) => { 
          const data = JSON.parse(message);
          console.log('[DEBUG]: received following data:', data);
          // ERROR - show message
          if(data.status === 'error') { console.error('[ERROR]:', data.msg); }
          // check that data has OK flag
          if(data.status !== 'ok') { return null; }
          // check data type
          if(data.type === 'view') {
            this.setState({ wsData: data.data });
          }
        });
        

      }
    }


    wsEmitData = (data: any) => {
      console.log('[DEBUG]: emit data:', data);
      if(SOCKET) {
        SOCKET.emit("data", JSON.stringify(data));
      }
    }


    resolveComponent () {
      if(this.state.authenticated === true) {
        return (
          <>
            <div className={classnames('ws-connection', { 'ws-connection--connected': this.state.wsConnected })}></div>
            <Component 
              { ...this.props } 
              wsConnected={ this.state.wsConnected } 
              wsData={ this.state.wsData } 
              dataCommandEmit={(data:any) => this.wsEmitData(data)}
            />
          </>
        );
      } else {
        return <Component { ...this.props } />;
      } 
    }

    render() {
      return this.resolveComponent();
    }


  }


  return WithWs;

}


export default withWs;