import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutEverywhere, fetchLoginLogs } from '../../../actions/auth.action';
import WithAuthentication from '../../hoc/auth/WithAuthentication';
import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import withRouter from '../../hoc/router/WithRouter';
import { tr, trans } from '../../../translation/translations';
import withWs from '../../hoc/ws/WithWs';
import fetchStates from '../../../types/fetchState.types';
import InterfaceLoginLog from '../../../intefaces/InterfaceLoginLog';
import InterfaceReducerAuth from '../../../intefaces/InterfaceReducerAuth';
import Box from '../../shared/box/Box';
import WithPreloader from '../../hoc/preloader/WithPreloader';
import moment from 'moment';
import TableFooter from '../../shared/table-footer/TableFooter';


interface SettingsSecurityProps {
  router: { location: string, navigate: (to: any) => any },
  auth: InterfaceReducerAuth,
  logoutEverywhere: () => Promise<void>,
  fetchLoginLogs: (options: { limit: number, skip: number }) => Promise<void>,
}


interface SettingsSecurityState {
  login_logs: Array<any>,
  login_logs_count: number,
  currentPage: number,
  perPage: number,
}


export class SettingsSecurity extends React.Component<SettingsSecurityProps, SettingsSecurityState> {


  state = {
    login_logs: [],
    login_logs_count: 0,
    perPage: 10,
    currentPage: 0,
  }


  componentDidMount = (): void => {
    const { perPage, currentPage } = this.state;
    this.reloadPage({ perPage, currentPage });
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.props.router.navigate(`/settings/security?page=0&perPage=${e.target.value}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage })
    this.props.router.navigate(`/settings/security?page=${prevPage}&perPage=${this.state.perPage}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage })
    this.props.router.navigate(`/settings/security?page=${nextPage}&perPage=${this.state.perPage}`)
  }


  reloadPage = async (options: { perPage: number, currentPage: number }) => {
    await this.props.fetchLoginLogs({ limit: options.perPage, skip: options.perPage * options.currentPage });
    if(this.props.auth.status === fetchStates.success) {
      const { login_logs, login_logs_count } = this.props.auth;
      this.setState({ login_logs, login_logs_count });
    }
  }


  handleLogoutEverywhere = async () => {
    await this.props.logoutEverywhere();
    await this.props.router.navigate('/login');
  }


  render() {

    const { login_logs, login_logs_count, currentPage, perPage } = this.state;

    return (
      <TemplatePageWithNavigation title={ tr(trans.viewSettingsSecurity.pageTitle) } buttonBack>
        <Box>
          <p className="paragraph paragraph--medium paragraph--margin-large">
            { tr(trans.viewSettingsSecurity.paragraphLogoutEverywhereInstructions) }
          </p>
          <div className="navigation-item" onClick={() => this.handleLogoutEverywhere()}>
            <NavLink to="/" className="button button--primary button--small">
              { tr(trans.viewSettingsSecurity.buttonLogoutEverywhere) }
            </NavLink>
          </div>
        </Box>
        <Box>
          <h2 className="form-section__title">{ tr(trans.viewSettingsSecurity.titleLoginList) }</h2>

          <div className="table">
            <div className="row header">
              <div className="cell">{ tr(trans.viewSettingsSecurity.tableHeader.time) }</div>
              <div className="cell">{ tr(trans.viewSettingsSecurity.tableHeader.email) }</div>
              <div className="cell">IP</div>
              <div className="cell">{ tr(trans.viewSettingsSecurity.tableHeader.loginSuccess) }</div>
            </div>
            {
              login_logs && login_logs.map((login_log: InterfaceLoginLog) => {
                return (
                  <div className="row" key={ login_log.login_id }>
                    <div className="cell" data-title={ tr(trans.viewSettingsSecurity.tableHeader.time) }>
                      { moment(login_log.login_tst*1000).format('DD/MM/YYYY HH:mm:ss') }
                    </div>
                    <div className="cell" data-title={ tr(trans.viewSettingsSecurity.tableHeader.email) }>
                      { login_log.login_email }
                    </div>
                    <div className="cell" data-title="IP">
                      { login_log.login_ip }
                    </div>
                    <div className="cell" data-title={ tr(trans.viewSettingsSecurity.tableHeader.loginSuccess) }>
                      { login_log.login_success === true ? 'true' : 'false' }
                    </div>
                  </div>
                )
              })
            }
          </div>
          <TableFooter 
            currentPage={ currentPage }
            perPage={ perPage }
            itemsCount={ login_logs_count }
            prevPage={() => this.prevPage(currentPage - 1)}
            nextPage={() => this.nextPage(currentPage + 1)}
            updatePagination={this.updatePagination}
          />
        </Box>
      </TemplatePageWithNavigation>
    );
  }
};


export default WithAuthentication(withWs(WithPreloader(withRouter(connect(
  ({ auth }) => ({ auth }),
  { logoutEverywhere, fetchLoginLogs }
)(SettingsSecurity)))));