import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchUserById, deleteUserById } from '../../../actions/person.action';

import WithAuthentication from '../../hoc/auth/WithAuthentication';
import withRouter from '../../hoc/router/WithRouter';
import withWs from '../../hoc/ws/WithWs';

import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import { tr, trans } from '../../../translation/translations';
import classnames from 'classnames';
import InterfaceReducerPerson from '../../../intefaces/InterfaceReducerPerson';
import Box from '../../shared/box/Box';
import WithPreloader from '../../hoc/preloader/WithPreloader';


interface UserDeleteProps {
  router: { location: string, navigate: (to: any) => any, params: { user_id: string } },
  person: InterfaceReducerPerson,
  fetchUserById: (user_id: string) => Promise<void>,
  deleteUserById: (user: any) => Promise<void>
}


interface UserDeleteState {
  user: {
    user_email: string,
    user_id: string
  },
  formSubmitted: boolean,
  formError: boolean
}


export class UserDelete extends React.Component<UserDeleteProps, UserDeleteState> {


  state = {
    user: {
      user_email: '',
      user_id: ''
    },
    formSubmitted: false,
    formError: false
  }


  componentDidMount(): void {
    this.getUserById();
  }


  getUserById = async () => {
    await this.props.fetchUserById(this.props.router.params.user_id);
    if(this.props.person.status === fetchStates.success) {
      const { user } = this.props.person;
      this.setState({ user });
    }
  }


  handleOnDeviceDeleteSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    await this.props.deleteUserById(this.state.user);
    this.setState({ formSubmitted: false });
    if(this.props.person.status === fetchStates.error) {
      this.setState({ formError: true });
      const self = this;
      setTimeout(function() { 
        if(self.state.formError === true) {
          self.setState({ formError: false });
        }
      }, 250);
    }
    if(this.props.person.status === fetchStates.success) {
      this.props.router.navigate('/users');
    }
    this.setState({ formSubmitted: false });
  }


  render() {

    const { formSubmitted, formError } = this.state;
    const { status } = this.props.person;


    return (
      <TemplatePageWithNavigation title={ tr(trans.viewUsersDelete.pageTitle) } buttonBack>
        <Box>
          <form className={classnames('form', { 'form-error': formError })} onSubmit={this.handleOnDeviceDeleteSubmit}>
            <div className="form-section">

              <div className="form-group">
                <input 
                  type="submit" 
                  value={ tr(trans.viewUsersDelete.form.buttonDeleteUser ) } 
                  className="button button--primary button--small"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>

            </div>
          </form>
        </Box>
      </TemplatePageWithNavigation>
    );
  }
};


export default WithAuthentication(withWs(WithPreloader(withRouter(connect(
  ({ person }) => ({ person }),
  { fetchUserById, deleteUserById }
)(UserDelete)))));