import React from 'react';
import { Navigate } from 'react-router';


interface ProtectedRouteProps {
  element: JSX.Element | JSX.Element[],
  requiredAuthorization: string,
}


const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, requiredAuthorization }) => {

  const user_authorized = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('user_authorized'))));

    
  const array_user_authorized = Object.entries(user_authorized)
  .filter((entry) => entry[1])
  .map((entry) => entry[0]);
  

  if(array_user_authorized.includes(requiredAuthorization)) {
    return (
      <>
        { element }
      </>
    );
  }

  return <Navigate to="/page-not-found" />;
};


export default ProtectedRoute;