import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchDeviceWallboxById } from '../../../actions/device.action';

import WithAuthentication from '../../hoc/auth/WithAuthentication';
import withRouter from '../../hoc/router/WithRouter';
import withWs from '../../hoc/ws/WithWs';

import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import InterfaceReducerDevice from '../../../intefaces/InterfaceReducerDevice';
import Box from '../../shared/box/Box';
import WithPreloader from '../../hoc/preloader/WithPreloader';
import { tr, trans } from '../../../translation/translations';




interface DeviceEditProps {
  router: { location: string, navigate: (to: any) => any, params: { device_id: string } },
  device: InterfaceReducerDevice,
  fetchDeviceWallboxById: (device_id: string) => Promise<void>,
  wsConnected: boolean,
  wsData: any,
  dataCommandEmit: any
}


interface DeviceEditState {
  device_wallbox: {
    device_title: string,
    device_serial: string,
    group_id: string
  }
}


export class DeviceEdit extends React.Component<DeviceEditProps, DeviceEditState> {


  state = {
    device_wallbox: {
      device_title: '',
      device_serial: '',
      group_id: '',
    }
  }


  componentDidMount(): void {
    this.getWallboxDeviceById();
  }


  getWallboxDeviceById = async () => {
    await this.props.fetchDeviceWallboxById(this.props.router.params.device_id);
    if(this.props.device.status === fetchStates.success) {
      const { device_wallbox } = this.props.device;
      this.setState({ device_wallbox });
      const data = { "ts": Math.floor(Date.now() / 1000), "data": [ "DEV_I", "PWR_I", "PWR_in", "PWR_out" ] };
      this.props.dataCommandEmit(data);
    }
  }


  pushDataCommandEmit= (data:any) => {
    this.props.dataCommandEmit(data);
  }


  render() {

    const { device_wallbox } = this.state;

    let DATA = {
      charging: false
    };

    if(this.props.wsData && this.props.wsData.device_serial && device_wallbox.device_serial === this.props.wsData.device_serial ) {
      DATA = this.props.wsData;
    };

    return (
      <TemplatePageWithNavigation title={ device_wallbox.device_title } buttonBack>
        <Box>

          <>
            { 
              DATA.charging === true ? (
                <>
                  <div>Device is currently charging</div>
                  <div>
                    <button className="button button--primary button--small" onClick={() => this.pushDataCommandEmit({ "device_serial": device_wallbox.device_serial, "message_type": "device", "message_command": "charge", "message_value": 0 })}>{ tr(trans.viewDevicesWallbox.tableButton.stopCharging) }</button> 
                  </div>
                </>
              ) : (
                <>
                  <div>Device is currently NOT charging</div>
                  <div>
                    <button className="button button--primary button--small" onClick={() => this.pushDataCommandEmit({ "device_serial": device_wallbox.device_serial, "message_type": "device", "message_command": "charge", "message_value": 1 })}>{ tr(trans.viewDevicesWallbox.tableButton.startCharging) }</button>
                  </div>
                </>
              )
            }
          </>
          <>
            { JSON.stringify(DATA, null, 2) }
          </>


        </Box>
      </TemplatePageWithNavigation>
    );
  }
};


export default WithAuthentication(withWs(WithPreloader(withRouter(connect(
  ({ device }) => ({ device }),
  { fetchDeviceWallboxById }
)(DeviceEdit)))));