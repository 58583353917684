import { GROUP, PRELOADER } from '../types/reducer.types';
import { errorNotification, successNotification } from '../functions/notifications';


const fetchGroup = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch:any) => {

  dispatch({ type: GROUP.FETCH });
  let authorizationHeader: any = [];
  dispatch({ type: PRELOADER.SHOW_PRELOADER });
  return fetch(`/api/group/${options.endpoint}`, options.options)
          .then(response => {
              authorizationHeader = response.headers.get('Authorization');
              return response.json();
            }
          )
          .then(json => {
            dispatch({ type: PRELOADER.HIDE_PRELOADER });
            if (json.errors !== undefined) {
              dispatch({ type: GROUP.FETCH_ERROR, ...json, authorizationHeader });
              json && json.errors && json.errors.map((item: {msg: string}) => {
                if(item.msg) {
                    errorNotification(item.msg);
                  }
                  return null;
              });
            } else {            
              dispatch({ type: options.SUCCESS_TYPE, ...json, authorizationHeader });
              json && json.success && json.success.map((item: {msg: string}) => {
                if(item.msg) {
                  successNotification(item.msg);
                }
                return null
              });
            }
          })
          .catch(error => dispatch({
            type: GROUP.FETCH_ERROR, errors: [{ msg: error }], authorizationHeader
          }));
  }


  export const fetchGroupsCount = () => fetchGroup({
    endpoint: `count`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: GROUP.FETCH_GROUPS_COUNT_SUCCESS
  });


  export const fetchGroups = (options: { limit: number, skip:number }) => fetchGroup({
    endpoint: `?limit=${options.limit}&skip=${options.skip}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: GROUP.FETCH_GROUPS_SUCCESS
  });


  export const fetchAllAccountGroups = () => fetchGroup({
    endpoint: `account`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: GROUP.FETCH_GROUPS_ACCOUNT_SUCCESS
  });


  export const fetchGroupById = (group_id: string) => fetchGroup({
    endpoint: `${group_id}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: GROUP.FETCH_GROUP_BY_ID_SUCCESS
  });


  export const addGroup = (group: any) => fetchGroup({
    endpoint: ``,
    options: {
      method: 'POST',
      body: JSON.stringify({ group }),
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
    },
    SUCCESS_TYPE: GROUP.FETCH_INSERT_GROUP_SUCCESS
  });


  export const updateGroupById = (group: any) => fetchGroup({
    endpoint: `${group.group_id}`,
    options: {
      method: 'PUT',
      body: JSON.stringify({ group }),
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
    },
    SUCCESS_TYPE: GROUP.FETCH_UPDATE_GROUP_BY_ID_SUCCESS
  });


  export const deleteGroupById = (group: any) => fetchGroup({
    endpoint: `${group.group_id}`,
    options: {
      method: 'DELETE',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
    },
    SUCCESS_TYPE: GROUP.FETCH_DELETE_GROUP_BY_ID_SUCCESS
  });


  export const fetchGroupsOverview = (options: { limit: number, skip:number }) => fetchGroup({
    endpoint: `overview/?limit=${options.limit}&skip=${options.skip}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: GROUP.FETCH_GROUPS_OVERVIEW_SUCCESS
  });